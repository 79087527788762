import _process from "process";
var exports = {};
var process = _process;
var si = typeof process.nextTick === "function",
    tick;

if (si) {
  tick = function (fn) {
    process.nextTick(fn);
  };
} else {
  tick = function (fn) {
    setTimeout(fn, 0);
  };
}

exports = tick;
export default exports;